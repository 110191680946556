<template>
  <el-dialog :model-value="visible" :title="title" width="80%" append-to-body @close="closeDialog">
    <div class="content-container">
      <div class="card-box">
        <div class="card-box-title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="8" v-for="item in columns" :key="new Date().getTime() + item.prop">
            {{ item.label + '：' }}
            <template v-if="item.slot">
              <slot :row="data" :prop="item.prop">
                {{ data[item.prop] }}
              </slot>
            </template>
            <span v-else>
              {{ data[item.prop] }}
            </span>
          </el-col>
        </el-row>
      </div>
      <slot name="cloudCode"></slot>
      <div v-if="data.stackList" class="card-box">
        <div class="card-box-title">垛位信息</div>
        <StackList ref="StackList" :data="data.stackList" :scene="scene" :visible="false"
          :carVisible="(data.transWay === '1') ? true : false" :parentData="data" :isShowRemark="isShowRemark"
          :isReceipt="isReceipt" :isCancel="isCancel" />
      </div>
      <div v-if="data.weighbridgeList && data.sourceWay !== '1'" class="card-box">
        <div class="card-box-title">磅单信息</div>
        <WeightTable ref="weightListTableRef" :data="data.weighbridgeList" :showCount="showCount" :scene="scene" />
      </div>
      <div v-if="data.files" class="card-box">
        <div class="card-box-title">附件信息</div>
        <UploadFile ref="filesRef" v-model="data.files" :scene="'detail'" :limit="limit(data.files)" />
      </div>
      <div v-if="data.goodsCertificateFile" class="card-box">
        <div class="card-box-title">货权凭证</div>
        <UploadFile ref="goodsCertificateFileRef" v-model="data.goodsCertificateFile" :scene="'detail'"
          :limit="limit(data.goodsCertificateFile)" />
      </div>
      <div v-if="data.inventoryFile" class="card-box">
        <div class="card-box-title">存货凭证</div>
        <UploadFile ref="inventoryFileRef" v-model="data.inventoryFile" :scene="'detail'"
          :limit="limit(data.inventoryFile)" />
      </div>
      <div v-if="data.railwayFile" class="card-box">
        <div class="card-box-title">铁路大票附件</div>
        <UploadFile ref="railwayFileRef" v-model="data.railwayFile" :scene="'detail'" :limit="limit(data.railwayFile)" />
      </div>
      <div v-if="data.shipFile" class="card-box">
        <div class="card-box-title">船运单据附件</div>
        <UploadFile ref="shipFileRef" v-model="data.shipFile" :scene="'detail'" :limit="limit(data.shipFile)" />
      </div>
      <div v-if="data.pipelineFile" class="card-box">
        <div class="card-box-title">管道运输附件</div>
        <UploadFile ref="pipelineFileRef" v-model="data.pipelineFile" :scene="'detail'"
          :limit="limit(data.pipelineFile)" />
      </div>
      <div v-if="data.storageContractFiles" class="card-box">
        <div class="card-box-title">仓库保管合同扫描件</div>
        <UploadFile ref="storageContractFilesRef" v-model="data.storageContractFiles" :scene="'detail'" :limit="5" />
      </div>
      <div v-if="data.saleContractFiles" class="card-box">
        <div class="card-box-title">货主买卖合同</div>
        <UploadFile ref="saleContractFilesRef" v-model="data.saleContractFiles" :scene="'detail'" :limit="5" />
      </div>
      <div v-if="data.certificateFiles" class="card-box">
        <div class="card-box-title">新货主货权凭证</div>
        <UploadFile ref="certificateFilesRef" v-model="data.certificateFiles" :scene="'detail'"
          :limit="limit(data.certificateFiles)" />
      </div>
      <div v-if="data.customDelegateFiles" class="card-box">
        <div class="card-box-title">客户授权书</div>
        <UploadFile ref="customDelegateFilesRef" v-model="data.customDelegateFiles" :scene="'detail'"
          :limit="limit(data.customDelegateFiles)" />
      </div>
      <div v-if="data.customDelegateFiles" class="card-box">
        <div class="card-box-title">合同附件</div>
        <UploadFile ref="customDelegateFilesRef" v-model="data.contractAnnex" :scene="'detail'"
                    :limit="limit(data.contractAnnex)" />
      </div>
      <div v-if="data.deliveryFiles" class="card-box">
        <div class="card-box-title">提货单</div>
        <UploadFile ref="deliveryFilesRef" v-model="data.deliveryFiles" :scene="'detail'"
          :limit="limit(data.deliveryFiles)" />
      </div>
      <div v-if="data.delegateLetterFiles" class="card-box">
        <div class="card-box-title">委托书</div>
        <UploadFile ref="delegateLetterFilesRef" v-model="data.delegateLetterFiles" :scene="'detail'"
          :limit="limit(data.delegateLetterFiles)" />
      </div>
      <div v-if="data.shippingContractFiles" class="card-box">
        <div class="card-box-title">发货协议</div>
        <UploadFile ref="shippingContractFilesRef" v-model="data.shippingContractFiles" :scene="'detail'"
          :limit="limit(data.shippingContractFiles)" />
      </div>
      <div v-if="data.outboundFiles" class="card-box">
        <div class="card-box-title">出库单据</div>
        <UploadFile ref="outboundFilesRef" v-model="data.outboundFiles" :scene="'detail'"
          :limit="limit(data.outboundFiles)" />
      </div>

    </div>
  </el-dialog>
</template>

<script setup name="OrderDetail">
const emits = defineEmits(['closeDialog'])
const { proxy } = getCurrentInstance()
const { capacity_unit } = proxy.useDict('capacity_unit')

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: '详情'
  },
  data: {
    type: Object,
    default: () => {
      return {}
    }
  },
  columns: {
    type: Array,
    default: () => {
      return []
    }
  },
  showCount: {
    type: Boolean,
    default: true
  },
  scene: {
    type: String,
    default: 'add'
  },
  isShowRemark: {
    type: Boolean,
    default: true
  },
  isReceipt: {
    type: Boolean,
    default: false
  },
  isCancel: {
    type: Boolean,
    default: false
  }
})

const limit = (str) => {
  return str ? str.split(',').length : 0
}

const closeDialog = () => {
  emits('closeDialog', false)
}
</script>

<style scoped lang="scss"></style>
