<template>
  <el-form ref="formRef" :model="form" :rules="rules" label-width="80px" @validate="handleFormValidate">
    <el-table :data="form.tableData" :border="border">
      <el-table-column type="index" width="55" label="序号">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].index`" label-width="0" :style="elFormItemClass">
            {{ $index + 1 }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="wbCode" label="磅单编号" width="150" v-if="scene === 'storage'">
        <template #default="{ row, $index }">
          <el-form-item label-width="0" :prop="`tableData[${$index}].wbCode`" :style="elFormItemClass">
            {{ row.wbCode }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="weighbridgeWeight" label="磅重" width="120" v-if="scene === 'storage'">
        <template #default="{ row, $index }">
          <el-form-item label-width="0" :prop="`tableData[${$index}].weighbridgeWeight`" :style="elFormItemClass">
            {{ row.weighbridgeWeight }}
            <dict-tag :options="capacity_unit" :value="row.weightUnit" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="roomName" label="库房" show-overflow-tooltip min-width="120" v-if="scene !== 'storage'">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].roomName`" label-width="0" :style="elFormItemClass">
            {{ row.roomName }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="stackName" show-overflow-tooltip min-width="150">
        <template #header>
          <div :class="scene === 'storage' ? 'cell-item setred' : 'cell-item'">垛位</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].stackName`" label-width="0" :style="elFormItemClass"
            :rules="scene === 'storage' ? rules.stackName : null">
            <StackSelect v-model="row.stackId" :stacks="stacks" @change="changeStack($event, row)" :isGetStack="false"
              :clearable="false" v-if="scene === 'storage' || (scene === 'edit' && wbOrderType !== 'GOODS_IN')" />
            <span v-else>{{ row.stackName }}</span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="productName" v-if="isShowProduct" :label="varietyCode === 'CF' ? '批次号' : '商品名称'"
        show-overflow-tooltip>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].productName`" label-width="0" :style="elFormItemClass">
            {{ row.productName }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="wbOrderType === 'GOODS_IN'" prop="remainAmount" label="剩余库容" min-width="100">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].remainAmount`" label-width="0" :style="elFormItemClass">
            {{ row.remainAmount }}<dict-tag :options="capacity_unit" :value="row.weightUnit" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="wbOrderType === 'GOODS_OUT'" prop="availableCount,availableWeight" label="可用库存" width="170">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].availableCount`" label-width="0" :style="elFormItemClass">
            {{ row.availableWeight }}
            <dict-tag :options="capacity_unit" :value="row.weightUnit" />
            /
            {{ row.availableCount }}
            <dict-tag :options="capacity_unit" :value="row.countUnit" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="availableCount,availableWeight" label="可注销张数" v-if="isCancel && scene == 'add'">
        <template #default="scope">
          {{ parseInt(scope.row.remainAmount / futuresLotTon) }}张
        </template>
      </el-table-column>
      <el-table-column prop="receiptCount" label="注销张数" width="150" v-if="isCancel">
        <template #default="scope">
          <el-input v-model="scope.row.receiptCount" placeholder="请输入张数" clearable @change="getWeightCount(scope.row)"
            v-if="scene == 'add'" />
          <div v-else>
            {{ scope.row.receiptCount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="storageBasis === '2'" prop="totalWeight" min-width="180">
        <template #header>
          <div class="cell-item setred">{{ weightName }}</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].totalWeight`" label-width="0"
            :rules="(scene !== 'add' || row.isNeedValidate) ? rules.totalWeight : null" :style="elFormItemClass">
            <el-input v-model="row.totalWeight" placeholder="请输入重量"
              @input="handleInput(row, wbOrderType, scene, showWeight)">
              <template #append>
                <dict-tag :options="capacity_unit" :value="weightUnit" />
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="totalCount" min-width="180">
        <template #header>
          <div class="cell-item setred">{{ countName }}</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].totalCount`" label-width="180px"
            :rules="(scene !== 'add' || row.isNeedValidate) ? rules.totalCount : null" :style="elFormItemClass">
            <div style="display: flex; align-items: center;">
              <el-input v-if="storageBasis === '2'" v-model="row.totalCount" placeholder="请输入数量">
                <template #append>
                  <dict-tag :options="capacity_unit" :value="countUnit" />
                </template>
              </el-input>
              <el-input v-else v-model="row.totalCount" placeholder="请输入数量" @input="handleInputCount(row)">
                <template #append>
                  <dict-tag :options="capacity_unit" :value="countUnit" />
                </template>
              </el-input>
              <!-- <span
                v-if="(scene === 'storage' || scene === 'edit' || (showWeight)) && row.totalCount > 0 && row.specificationVal > 0"
                style="margin-left: 10px;font-size: 12px; width: 100%; color: #666">规格重量:
                {{ multiply(row.totalCount, row.specificationVal) }} <dict-tag :options="capacity_unit"
                  :value="row.weightUnit" /></span> -->
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="storageBasis === '1'" prop="totalWeight" min-width="180">
        <template #header>
          <div class="cell-item setred">{{ weightName }}</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].totalWeight`" label-width="0"
            :rules="(scene !== 'add' || row.isNeedValidate) ? rules.totalWeight : null" :style="elFormItemClass">
            <el-input v-model="row.totalWeight" placeholder="请输入重量" @input="row.isNeedValidate = true">
              <template #append>
                <dict-tag :options="capacity_unit" :value="weightUnit" />
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <template v-if="carVisible">
        <el-table-column prop="truckTime" width="160">
          <template #header>
            <div :class="scene === 'storage' ? 'cell-item' : 'cell-item setred'">{{ wbOrderType === 'GOODS_OUT' ? '车辆出库日期'
              : '车辆入库日期' }}</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].truckTime`"
              :rules="(scene !== 'add' || row.isNeedValidate) ? rules.truckTime : null" :style="elFormItemClass">
              <el-date-picker v-model="row.truckTime" type="date" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择日期"
                style="width: 100%" :disabled-date="pickerOptions" v-if="scene !== 'storage'"
                @change="changeTime(row)"></el-date-picker>
              <span v-else>{{ row.truckTime || row.createTime }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="carPlateNum" width="140">
          <template #header>
            <div :class="scene === 'storage' ? 'cell-item' : 'cell-item setred'">车牌号</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].carPlateNum`"
              :rules="(scene !== 'add' || row.isNeedValidate) ? rules.carPlateNum : null" :style="elFormItemClass">
              <el-select v-model="row.carPlateNum" filterable allow-create placeholder="请选择车牌" @click="getCar(row)"
                @change="changeCar($event, row)" value-key="id" default-first-option v-if="scene !== 'storage'">
                <el-option v-for="item in cars" :key="item.id" :label="item.carPlateNum" :value="item">
                  <span style="float: left">{{ item.carPlateNum }}</span>
                  <span style=" float: right;color: var(--el-text-color-secondary);font-size: 13px;padding-left: 20px;">{{
                    (item.isShow ? '已上传 ' : ' ') + item.time
                  }}</span>
                </el-option>
              </el-select>
              <span v-else>{{ row.carPlateNum }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="wbCode" v-if="showWeight" width="100">
          <template #header>
            <div class="cell-item setred">关联榜单</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].wbCode`" :rules="rules.wbCode"
              :style="elFormItemClass">
              <el-link :disabled="!row.carPlateNum" type="primary" round @click="relevanceWB(row, $index)">{{
                row.wbCode ? '已关联榜单' : '选择磅单' }}</el-link>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleVoucher" :width="'115px'">
          <template #header>
            <div :class="scene === 'add' ? 'cell-item' : 'cell-item setred'">车辆附件</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].vehicleVoucher`" :style="elFormItemClass"
              :rules="scene === 'edit' ? rules.vehicleVoucher : null">
              <div>
                <el-link type="primary" @click="showCarFile(row, $index)" style="margin-right: 5px;">{{
                  row.vehicleVoucher ? '查看'
                  : '上传' }}</el-link>
                <el-link type="primary" @click="showQrcode(row)"
                  v-if="isShowFile && row.id !== 0 && row.id !== ''">手机上传</el-link>
              </div>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="物联网" prop="things" width="100">
          <template #default="{ row, $index }">
            <el-form-item :prop="`tableData[${$index}].things`" :style="elFormItemClass">
              <el-button type="text" @click="showNetwork(row)">查看</el-button>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="driverName" label="司机名称" width="100">
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].driverName`" :style="elFormItemClass">
              <el-input v-model="row.driverName" placeholder="请输入" v-if="scene !== 'storage'" />
              <span v-else>{{ row.driverName }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="driverPhone" label="司机电话" width="140">
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].driverPhone`" :rules="rules.driverPhone"
              :style="elFormItemClass">
              <el-input v-model="row.driverPhone" placeholder="请输入" v-if="scene !== 'storage'" />
              <span v-else>{{ row.driverPhone }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号" width="180" show-overflow-tooltip>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].idCard`" :rules="rules.idCard"
              :style="elFormItemClass">
              <el-input v-model="row.idCard" placeholder="请输入" v-if="scene !== 'storage'" />
              <span v-else>{{ row.idCard }}</span>
            </el-form-item>
          </template>
        </el-table-column>
      </template>
      <el-table-column prop="remark" label="备注" width="150" v-if="isShowRemark">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].remark`" :style="elFormItemClass">
            <el-input v-model="row.remark" placeholder="请输入备注" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="110">
        <template #default="{ row, $index }">
          <el-form-item label-width="0" :style="elFormItemClass">
            <el-link type="primary" @click="addRow(row)" v-if="scene !== 'add'">添加</el-link>
            &nbsp;&nbsp;
            <el-link type="primary" @click="removeRow($index, row)">移除</el-link>
            <!--            <el-link type="primary"-->
            <!--              v-if="wbOrderType === 'GOODS_OUT' && scene === 'storage' || (showWeight && wbOrderType === 'GOODS_OUT') || (scene === 'edit' && wbOrderType === 'GOODS_OUT')"-->
            <!--              @click="takeStock($index, row, productCode, companyCode)">-->
            <!--              库存盘点-->
            <!--            </el-link>-->
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
  <StatisticInfo class="statistic-static">
    <div class="statistic-item">合计：</div>
    <div v-if="storageBasis === '2'" class="statistic-item">
      总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="weightUnit" />
    </div>
    <div class="statistic-item">
      总数量：<span class="count">{{ totalCount.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="countUnit" />
    </div>
    <div v-if="storageBasis === '1'" class="statistic-item">
      总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="weightUnit" />
    </div>
  </StatisticInfo>
  <!--物联网-->
  <OperationDetailsDialog v-model="thingsDialogVisible" :data="selectItem.thingsInfo" />
  <car-file v-model:isShow="isShowCarFile" :vehicleVoucher="selectItem?.vehicleVoucher" :truckId="selectItem?.id + ''"
    :orderType="wbOrderType === 'GOODS_IN' ? '1' : '2'" @changeVv="changeVehicleVoucher" flag="updateTruckFilePc"
    :isSaveFile="scene !== 'add' && selectItem?.id !== 0 && selectItem?.id !== ''"></car-file>
  <!-- 生成二维码 -->
  <el-dialog :title="`请用微信扫描二维码上传凭证(ID:${selectItem ? selectItem.id : ''})`" v-model="updateCodeShow" width="400px"
    @close="getWXUploadFiles(selectItem.id)" append-to-body>
    <div style=" display: flex;flex-direction: column;align-items: center;">
      <qrcode-vue :value="qrcodeData" :size="250"></qrcode-vue>
      <div style="padding-top: 20px;">请上传完毕再关闭此页面</div>
    </div>
  </el-dialog>
  <!--选择磅单-->
  <SelectWeightList ref="selectWeightListRef" v-model="weightVisible" :wbOrderType="wbOrderType"
    :carPlateNum="selectItem.carPlateNum" :wbCode="selectItem.wbCode" @submit="sureSelectWB"
    @cancel="weightVisible = false" />
  <!--  <el-dialog v-model="stockVisible" title="库存盘点" width="60%" append-to-body>-->
  <!--    <el-table :data="stockData" :border="border" @selection-change="handleSelectionChange">-->
  <!--      <el-table-column type="selection" width="55"></el-table-column>-->
  <!--      <el-table-column label="序号" width="55">-->
  <!--        <template #default="scope">-->
  <!--          {{ scope.$index + 1 }}-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="cloudCode" label="云仓单号" width="160" />-->
  <!--      <el-table-column prop="productName" label="物品名称" show-overflow-tooltip />-->
  <!--      <el-table-column prop="specificationVal" label="规格" show-overflow-tooltip />-->
  <!--      <el-table-column prop="remainWeight" label="磅重(吨)" show-overflow-tooltip />-->
  <!--      <el-table-column label="实盘重量" width="120">-->
  <!--        <template #default="scope">-->
  <!--          <el-input v-model="scope.row.firmOfferWeight" type="number" placeholder="" @input="updateProfitLoss(scope.row)"-->
  <!--            @keypress="handleKeyPress"></el-input>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="weight" label="盈亏重量" show-overflow-tooltip />-->
  <!--      <el-table-column prop="remainCount" label="系统数量" show-overflow-tooltip />-->
  <!--      <el-table-column label="实盘数量" width="120">-->
  <!--        <template #default="scope">-->
  <!--          <el-input placeholder="" type="number" v-model="scope.row.firmOfferCount" @input="updateProfitLoss(scope.row)"-->
  <!--            @keypress="handleKeyPress"></el-input>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="count" label="盈亏数量" show-overflow-tooltip>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="result" label="盘点结果" show-overflow-tooltip>-->
  <!--        <template #default="scope">-->
  <!--          <template v-if="scope.row.count < 0 && scope.row.varietyCode === 'CF'">-->
  <!--            <span style="color: red">盘亏</span>-->
  <!--          </template>-->
  <!--          <template v-if="scope.row.count >= 0 && scope.row.varietyCode === 'CF'">-->
  <!--            <span style="color: green">盘盈</span>-->
  <!--          </template>-->
  <!--          <template v-if="scope.row.weight < 0 && scope.row.varietyCode !== 'CF'">-->
  <!--            <span style="color: red">盘亏</span>-->
  <!--          </template>-->
  <!--          <template v-if="scope.row.weight >= 0 && scope.row.varietyCode !== 'CF'">-->
  <!--            <span style="color: green">盘盈</span>-->
  <!--          </template>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column label="备注" width="120">-->
  <!--        <template #default="scope">-->
  <!--          <el-input v-model="scope.row.remark" placeholder="请输入备注"></el-input>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--    </el-table>-->
  <!--    <div style="text-align: right;">-->
  <!--      <el-button type="primary" class="confirm-button" @click="confirmStockTaking">确定盘库</el-button>-->
  <!--    </div>-->
  <!--  </el-dialog>-->
</template>
<script setup name="indexEdit">
import { watch, onMounted } from 'vue'
import { Decimal } from 'decimal.js';
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import {
  firmOfferStock,
  listPlateNosForDate,
  selectCloudByCompanyProductStack
} from '@/api/wms/appointment/inAppointment'
import { getTruckFile } from '@/api/wms/appointment/outAppointment'
import OperationDetailsDialog from '@/components/OperationDetailsDialog/ImagesDialog.vue'
import carFile from '@/views/wms/order/components/carFile.vue'
import QrcodeVue from "qrcode.vue"
import SelectWeightList from '@/components/SelectWeightList/index.vue'
import StackSelect from '@/components/StackSelect'
import { getThings } from '@/api/wms/appointment/inAppointment'
import { ElMessageBox } from "element-plus"
import { getQrCodeUrl } from '@/utils/index'
const store = useStore()
const { proxy } = getCurrentInstance()
const { capacity_unit } = proxy.useDict('capacity_unit')
const pickerOptions = (time) => {
  // 定义禁止选择的时间范围
  const now = new Date();
  const targetDate = new Date(time.getFullYear(), time.getMonth(), time.getDate());
  return targetDate >= now; // 禁用今天之前的日期
}

const props = defineProps({
  scene: {
    type: String,
    default: 'add'
  },
  data: {
    type: Array,
    default: () => {
      return []
    }
  },
  border: {
    type: Boolean,
    default: true
  },
  carVisible: {
    type: Boolean,
    default: false
  },
  isShowProduct: {
    type: Boolean,
    default: false
  },
  isShowRemark: {
    type: Boolean,
    default: false
  },
  countName: {
    type: String,
    default: '数量'
  },
  weightName: {
    type: String,
    default: '分配重量'
  },
  companyCode: {
    type: String,
    default: ''
  },
  productCode: {
    type: String,
    default: ''
  },
  wbOrderType: {
    type: String,
    default: 'GOODS_IN'
  },
  showWeight: {
    type: Boolean,
    default: false
  },
  isShowFile: {
    type: Boolean,
    default: false
  },
  stacks: {
    type: Array,
    default: []
  },
  isCancel: {
    type: Boolean,
    default: false
  }
})
const form = reactive({ tableData: [] })
const emit = defineEmits(['removeIndex', 'TableValidate', 'confirm-and-refresh', 'changeGoods'])
const totalCount = ref(0)
const totalWeight = ref(0)
const storageBasis = ref()
const weightUnit = ref()
const varietyCode = ref()
const countUnit = ref()
const reservation = (rule, value, callback) => {
  const reg = /^(?!0(?:\.0{0,4})?$)\d*(?:\.\d{1,4})?$/
  if (reg.test(value)) {
    callback()
  } else {
    callback(new Error("请输入正确的格式"))
  }
}

const rules = ref({
  stackName: [{ required: true, message: '请选择垛位', trigger: ['change', 'input'] }],
  totalCount: [
    { required: true, message: '数量不能为空', trigger: ['change', 'input'] },
    { required: true, validator: reservation, trigger: ['change', 'input'] }
  ],
  totalWeight: [
    { required: true, message: '重量不能为空', trigger: ['change', 'input'] },
    { required: true, validator: reservation, trigger: ['change', 'input'] }
  ],
  truckTime: [
    { required: true, message: "请输入车辆出库时间", trigger: 'blur' }
  ],
  carPlateNum: [
    { required: true, message: '请输入车牌号', pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/, trigger: ['change', 'input', 'blur'] },
  ],
  driverPhone: [
    { required: false, message: '请输入正确的手机号', pattern: /^1[356789]\d{9}$/, trigger: ['change', 'input', 'blur'] }
  ],
  idCard: [
    { required: false, message: '请输入正确的身份证号', pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, trigger: ['change', 'input', 'blur'] }
  ],
  vehicleVoucher: [
    { required: true, message: '请上传车辆附件', trigger: ['change', 'input', 'blur'] },
  ],
  wbCode: [
    { required: true, message: '请关联榜单', trigger: ['change', 'input', 'blur'] },
  ],

})
const handleFormValidate = (valid, bol) => {
  if (bol !== undefined && bol === false) {
    elFormItemClass.value = { marginBottom: '18px' }
  }
  // else if (bol !== undefined && bol === true) {
  //   elFormItemClass.value = { marginBottom: '0px' }
  // }
}

const elFormItemClass = ref({ marginBottom: '0px' })


// 获取垛位列表
const stacks = ref([])
const checkForm = () => {
  proxy.$refs.formRef.validate((valid) => {
    console.log(valid)
    emit('TableValidate', valid)
  })
}
const formRef = ref({})
// 是否显示司机附件弹窗
const isShowCarFile = ref(false)
const selectItem = ref({})
const updateCodeShow = ref(false)
const qrcodeData = ref()
const stockVisible = ref(false)
const stockData = ref([])
const showCarFile = async (item, index) => {
  selectItem.value = item
  selectIndex.value = index
  isShowCarFile.value = true
}

const changeVehicleVoucher = (data) => {
  console.log(data)
  selectItem.value.vehicleVoucher = data
  console.log(form.tableData)
  formRef.value.validateField(`tableData[${selectIndex.value}].vehicleVoucher`, () => null);
}

//关闭二维码页面时获取最新的凭证
const getWXUploadFiles = async (apmId) => {
  const { code, data } = await getTruckFile(apmId + '/' + (props.wbOrderType === 'GOODS_IN' ? '1' : '2'))
  if (code === 200) {
    selectItem.value.vehicleVoucher = data
  }
}
const showQrcode = (row) => {
  updateCodeShow.value = true
  selectItem.value = row
  qrcodeData.value = getQrCodeUrl(row.id, props.wbOrderType === 'GOODS_IN' ? '1' : '2')
}
const addRow = (row) => {
  const newRow = JSON.parse(JSON.stringify(row))
  if (props.scene === 'storage') {
    newRow.availableCount = ''
    newRow.availableWeight = ''
    newRow.stackId = ''
    newRow.stackCode = ''
    newRow.stackName = ''
    newRow.totalWeight = ''
    newRow.totalCount = ''
  } else if (props.scene === 'edit') {
    newRow.carPlateNum = ''
    newRow.id = 0
    newRow.vehicleVoucher = ''
  }
  form.tableData.push(newRow)
  nextTick(() => {//解决新生成的垛位没有可选值的问题
    stacks.value = JSON.parse(JSON.stringify(stacks.value))
  })
}

import { getCurrentInstance } from 'vue';
const instance = getCurrentInstance(); // 获取当前组件实例
const confirmAndRefresh = () => {
  console.log("触发自定义事件")
  instance.emit('confirm-and-refresh'); //
}

//盘点
const takeStock = (index, row, productCode, companyCode) => {
  const data = {
    roomId: row.roomId,
    stackId: row.stackId,
    whCode: row.whCode,
    productCode: productCode,
    companyCode: companyCode
  };
  console.log("data", data);
  const { code, rows } = selectCloudByCompanyProductStack(data)
    .then(response => {
      const { code, rows } = response;
      console.log("code", code);
      if (code === 200) {
        stockVisible.value = true
        stockData.value = rows
      }
    })
}

const removeRow = (index, row) => {
  if (form.tableData.length === 1 && (varietyCode.value !== 'CF' || props.scene === 'edit')) {
    ElMessage({
      type: 'warning',
      message: '数据最少为一条'
    })
    return
  }
  if (row.id) {
    ElMessageBox.confirm("确定移除本行数据吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteCurrentRow(row, index)
      })
      .catch(() => { })
  } else {
    deleteCurrentRow(row, index)
  }
}
const deleteCurrentRow = (row, index) => {
  emit('removeIndex', index, row)
  form.tableData.splice(index, 1)
  if (row.wbCode && !form.tableData.find(item => item.wbCode === row.wbCode)) {
    filterWbCode.value.push(row.wbCode)
    filterWbCode.value = [...new Set(filterWbCode.value)];
    console.log("filterWbCode", filterWbCode.value)
  }
  // calculate(row)
}

const filterWbCode = ref([])
const getWeightCount = (row) => {
  const { receiptCount, specificationVal } = row
  row.totalWeight = futuresLotTon.value * receiptCount
  row.totalCount = (futuresLotTon.value * receiptCount / specificationVal).toFixed(4) * 1
}
// 根据 storageBasis 计算数量和重量
const handleInput = (row, wbOrderType, scene, showWeight) => {
  console.log("wbOrderType", wbOrderType)
  console.log("scene", scene)
  console.log("showWeight", showWeight)
  const { totalWeight, totalCount, specificationVal } = row
  let num = (totalWeight / specificationVal).toFixed(4) * 1
  console.log(num)
  if (Number.isNaN(num)) {
    return
  }
  row.totalCount = (totalWeight / specificationVal).toFixed(4) * 1
  row.isNeedValidate = true;
}

const handleInputCount = (row) => {
  const { totalCount, specificationVal } = row
  console.log("totalCount", totalCount)
  console.log("specificationVal", specificationVal)
  let num = (totalCount * specificationVal).toFixed(4) * 1
  console.log(num)
  if (Number.isNaN(num)) {
    return
  }
  row.totalWeight = (totalCount * specificationVal).toFixed(4) * 1
  row.isNeedValidate = true;
}


const calculate = (row, wbOrderType, scene, showWeight) => {
  const { totalWeight, totalCount, specificationVal } = row
  console.log("scene", scene)
  if (scene === 'add') {
    return;
  }
  const totalCountNew = new Decimal(totalCount);
  const specificationValNew = new Decimal(specificationVal);
  const totalWeightNew = new Decimal(totalWeight);
  const result = totalCountNew.times(specificationValNew).eq(totalWeightNew);
  console.log("result", result)
  if (!result) {
    const message = '您输入的数量不等于重量/规格，请核查！';
    const confirmButtonText = '知道了';
    const type = 'warning';
    const showCancelButton = false;
    ElMessageBox.confirm(`${message}`, '提示', {
      confirmButtonText,
      type,
      showCancelButton,
    });
  }
}

const summation = () => {
  totalCount.value = 0
  totalWeight.value = 0
  form.tableData.forEach(item => {
    totalCount.value += item?.totalCount ? item.totalCount * 1 : 0
    totalWeight.value += item?.totalWeight ? item.totalWeight * 1 : 0
  })
}
const changeTime = async (row) => {
  row.carPlateNum = ''
  cars.value = []
}
// 车牌列表
const cars = ref([])
const getCar = async (row) => {
  if (row && row.truckTime) {
    const { code, rows } = await listPlateNosForDate({ admissionDate: row.truckTime, tenantId: store?.getters.tenantId })
    if (code === 200 && rows && rows.length >= 1) {
      cars.value = rows.map((item) => ({
        ...item, carPlateNum: item.plateNo, time: getTime(item.admissionDate), url: item.picOssUrl,
      }))
    } else {
      cars.value = []
    }
  }
}
const getTime = (timeString) => {
  const date = new Date(timeString);
  // 获取小时、分钟和秒  
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}
const getDate = (dateString) => {
  // 将日期字符串转换为日期对象
  const dateObj = new Date(dateString);
  // 提取年、月、日
  const year = dateObj.getFullYear().toString();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1
  const day = dateObj.getDate().toString().padStart(2, '0');

  // 组合成年月日字符串
  return `${year}-${month}-${day}`;
}
const changeCar = (car, row) => {
  console.log(car)
  if (typeof car === 'string') {
    row.carPlateNum = car
    return
  }
  row.carPlateNum = car.carPlateNum
  row.driverName = car.driverName
  row.driverPhone = car.driverPhone
  row.idCard = car.idCard
  row.vehicleVoucher = car.vehicleVoucher
  row.goodsCertificateFile = car.goodsCertificateFile
  row.truckTime = getDate(row.truckTime) + ' ' + car.time
  let resDataArray = car.goodsCertificateFile ? car.goodsCertificateFile.split(',') : []
  form.tableData.forEach(item => {
    resDataArray = resDataArray.concat(item.goodsCertificateFile?.split(','))
  })
  emit('changeGoods', resDataArray)
  if (car.url) {
    row.isThing = true
  }

}
const changeStack = (event, row) => {
  const { val, selectedData } = event
  console.log(val, selectedData)
  row.stackCode = selectedData[0].stackCode
  row.stackName = selectedData[0].stackName
  row.stackId = selectedData[0].stackId
  row.roomCode = selectedData[0].roomCode
  row.roomId = selectedData[0].roomId
  row.roomName = selectedData[0].roomName
  row.availableCount = selectedData[0].availableCount
  row.availableWeight = selectedData[0].availableWeight
  row.remainAmount = selectedData[0].remainAmount
}

const selectedRows = ref([]);

const handleSelectionChange = (selection) => {
  selectedRows.value = selection;
}

// 处理键盘按下事件，限制输入为数字并最多保留四位小数
function handleKeyPress(event) {
  const charCode = (typeof event.which === "undefined") ? event.keyCode : event.which;
  const charStr = String.fromCharCode(charCode);
  const regex = /^\d*\.?\d{0,4}$/;
  if (!regex.test(charStr)) {
    event.preventDefault();
  }
}
function updateProfitLoss(row) {
  if (row.firmOfferWeight !== undefined && row.remainWeight !== undefined && row.remainWeight !== '' && row.firmOfferWeight !== '') {
    row.weight = (parseFloat(row.remainWeight) - parseFloat(row.firmOfferWeight)).toFixed(2);
  } else {
    row.weight = 0;
  }
  if (row.firmOfferCount !== undefined && row.remainCount !== undefined && row.remainCount !== '' && row.firmOfferCount !== '') {
    row.count = (parseFloat(row.remainCount) - parseFloat(row.firmOfferCount)).toFixed(2);
  } else {
    row.count = 0
  }
}
const confirmStockTaking = () => {
  if (selectedRows.value.length === 0) {
    ElMessage({
      type: 'warning',
      message: '请勾选盘库数据'
    });
    return;
  } else {
    const missingWeight = selectedRows.value.some(row => !row.firmOfferWeight);

    if (missingWeight) {
      ElMessage({
        type: 'warning',
        message: '实盘重量不能为空，请填写实盘重量'
      });
      return;
    }
    const count = selectedRows.value.some(row => !row.firmOfferCount);

    if (count) {
      ElMessage({
        type: 'warning',
        message: '实盘数量不能为空，请填写实盘数量'
      });
      return;
    }
    const remark = selectedRows.value.some(row => !row.remark || row.remark.trim() === '');
    if (remark) {
      ElMessage({
        type: 'warning',
        message: '请输入盘库原因'
      });
      return;
    }
    const selectedRowsJson = JSON.stringify(selectedRows.value);
    firmOfferStock(selectedRowsJson)
      .then(response => {
        const { code, msg } = response;
        console.log("code", code)
        if (code === 200) {
          ElMessage({
            type: 'success',
            message: '盘库成功'
          });
          stockVisible.value = false;
          confirmAndRefresh()
        } else {
          ElMessage({
            type: 'error',
            message: msg
          });
        }
      })
  }

};

function multiply(a, b) {
  if (a === 'undefined' || b === 'undefined') {
    return;
  }
  const result = a * b;
  const formattedResult = result.toFixed(4).replace(/\.?0+$/, '');
  return formattedResult;
}


watch(
  () => form.tableData,
  (val) => {
    if (val.length > 0) {
      summation()
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => props.data,
  (val) => {
    form.tableData = val
  },
  {
    immediate: true,
    deep: true
  }
)
watch(
  () => props.stacks,
  (val) => {
    stacks.value = val
  },
  {
    immediate: true,
    deep: true
  }
)
const futuresLotTon = ref()
watch(
  () => store.state.variety.itemObj,
  (itemObj) => {
    if (itemObj) {
      storageBasis.value = itemObj.storageBasis
      weightUnit.value = itemObj.goodsWeightUnit
      countUnit.value = itemObj.goodsCountUnit
      varietyCode.value = itemObj.varietyCode
      futuresLotTon.value = itemObj.futuresLotTon
      console.log(futuresLotTon.value)
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
})
defineExpose({
  form,
  formRef,
  totalWeight,
  totalCount,
  checkForm,
  filterWbCode
})

// 物联网信息
const thingsDialogVisible = ref(false)
const showNetwork = async (row) => {
  selectItem.value = row
  const { code, msg } = await getThings({ admissionDate: row.truckTime || row.createTime, plateNo: row.carPlateNum })
  if (code === 200) {
    row.thingsInfo = {
      operations: [{
        images: [{
          title: `${row.carPlateNum}抓拍`,
          needTransfer: true,
          url: msg,
          isVideo: false
        }],
      }]
    }
  }
  if (selectItem.value.thingsInfo) {
    thingsDialogVisible.value = true
  } else {
    ElMessage({
      type: 'warning',
      message: '暂无信息'
    })
  }
}
const weightVisible = ref(false)
const selectIndex = ref()
const relevanceWB = async (row, index) => {
  selectItem.value = row
  selectIndex.value = index
  weightVisible.value = true
}
const sureSelectWB = (row) => {
  console.log(row)
  // 毛重: grossWeight、皮重:tareWeight 扣重: deductWeight 毛重-皮重-扣重=实际重量
  const { wbCode, realWeight, goodsCount } = row
  selectItem.value.totalWeight = realWeight
  if (goodsCount && goodsCount != 0) {
    selectItem.value.totalCount = goodsCount
  } else {
    selectItem.value.totalCount = (realWeight / selectItem.value.specificationVal).toFixed(4)
  }
  selectItem.value.wbCode = wbCode
  console.log(selectItem.value)
  weightVisible.value = false
  formRef.value.validateField(`tableData[${selectIndex.value}].wbCode`, () => null);
}
</script>
<style lang="scss" scoped>
.statistic {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  padding: 0 20px;
  height: 45px;
  font-size: 16px;
  color: rgba(0, 0, 0, .85);
  font-weight: 650;
  //   box-shadow: 6px 6px 12px 6px rgba(0,0,0,0.08);
  background-color: #fff;

  &-item {
    .count {
      color: #337FFF;
      font-size: 18px;
    }
  }
}

:deep(.el-form-item__content) {
  margin-left: 0 !important;
}

.el-upload-list--picture-card .el-upload-list__item,
.el-upload--picture-card {
  width: 50px !important;
  height: 50px !important;
}


/* 设置 el-table 行高 */
.el-table .el-table__body-wrapper tbody>tr>td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* 设置 el-table-header-cell 行高 */
.el-table .el-table__header-wrapper thead>tr>th {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}



.el-upload-list__item-preview {
  display: none !important;
}

.el-upload-list--picture-card .el-upload-list__item-actions span+span {
  margin-left: 0 !important;
}

.setred::before {
  content: "*";
  color: red;
  font-weight: bold;
  font-size: 16px;
  padding-right: 5px;
}
</style>